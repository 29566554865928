<template>
  <div class="wizard card">
    <h4>{{ t('chooseShowcaseType') }}</h4>
    <div class="container mt-3">
      <div class="row radioBtnSet">
        <div class="col-lg-12">
          <div class="input-wrap">
            <div class="row">
              <div class="col-lg-4">
                <router-link :to="{name: 'Create.Horizontal'}">
                  Banner Slider
                </router-link>
              </div>
              <div class="col-lg-4">
                <router-link :to="{name: 'Create.Vertical'}">
                  Banner Image
                </router-link>
              </div>
              <div class="col-lg-4">
                <router-link :to="{name: 'Create.Layout1'}">
                  Product Grid
                </router-link>
              </div>
              <div class="col-lg-4">
                <router-link :to="{name: 'Create.Layout2'}">
                  Collection Grid
                </router-link>
              </div>
              <div class="col-lg-4">
                <router-link :to="{name: 'Create.Layout3'}">
                  Product Slider
                </router-link>
              </div>
              <div class="col-lg-4">
                <router-link :to="{name: 'Create.SocialMedia'}">
                  Social Media
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <router-view :collections="collections"/>
</template>

<script>
import useGlobal from '../../composables/useGlobal'

export default {
  name: 'CreateShowCase',
  components: {},
  props: {
    collections: Array,
  },
  setup() {
    const { t } = useGlobal()

    return {
      t,
    }
  },
}
</script>

<style scoped>

.radioBtnSet .input-wrap a {
  background-color: var(--primary);
  color: #fff;
  font-weight: bold;
  padding: 1em;
  border-radius: 3px;
  box-shadow: 1px 1px 1px lightgray;
  cursor: pointer;
  transition: all .2s;
  opacity: .8;
  text-decoration: none;
  display: block;
  text-align: center;
  margin-bottom: 1em;
}

.radioBtnSet .input-wrap a:hover {
  opacity: 1;
  transition: all .2s;
  box-shadow: 4px 5px 2px lightgray;
}

.router-link-exact-active {
  background: gray !important;
}

</style>
